import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlattener } from '@angular/material/tree';
import { TreeControl } from '@angular/cdk/tree';
import { FlatBomNode } from 'src/app/featureModules/home/flat-bom-node.model';
import { BomNode } from 'src/app/featureModules/home/bom-node.model';
import { DataSource } from '@angular/cdk/collections';
import { CategoriesBom } from '../../interfaces/categories-bom.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterFields } from '../../interfaces/filter-fields.interface';

@Component({
  selector: 'bill-of-material-table',
  templateUrl: './bill-of-material-table.component.html',
  styleUrls: ['./bill-of-material-table.component.scss'],
})
export class BillOfMaterialTableComponent {
  
  @Input() treeControl: TreeControl<FlatBomNode>;
  @Input() treeFlattener: MatTreeFlattener<() => {}, TreeControl<FlatBomNode>>;
  @Input() dataSource: DataSource<BomNode>;
  @Input() transformer: any;
  @Input() totalItems: number = 0;
  @Input() categoryList: CategoriesBom[] = [];
  @Input() displayedColumns: string[] = [
    'select',
    'OrderLevelValue',
    'OrderPathValue',
    'articlenumber',
    'BOMItemDescription',
    'Quantity',
    'Unit',
    'BOMItemCategory',
    'Traceable',
  ];
  @Input() heightTable: string = '400px';

  @Output() componentDataEvent: EventEmitter<FlatBomNode> = new EventEmitter<FlatBomNode>();
  @Output() searchFilterEvent: EventEmitter<FilterFields> = new EventEmitter<FilterFields>();
  @Output() sapConfigEvent: EventEmitter<void> = new EventEmitter<void>();

  isDataExpanded: boolean = false;
  isFiltersBlockVisible: boolean = false;
  filterForm: FormGroup = this.formBuilder.group({
    articleNumber: [''],
    description: [''],
    categories: [[]],
  });
  filterFieldsValues: FilterFields;
  enableSapConfigButton: boolean = false;
  

  constructor(private formBuilder: FormBuilder) {}

  onRadioChange(node: FlatBomNode): void {
    this.componentDataEvent.emit(node);
    this.enableSapConfigButton = true;
  }

  showFiltersBlock(): void {
    this.isFiltersBlockVisible = !this.isFiltersBlockVisible;
  }

  expandData(): void {
    !this.isDataExpanded ? this.treeControl.expandAll() : this.treeControl.collapseAll();
    this.isDataExpanded = !this.isDataExpanded;
  }

  setPaddingLevel(level: string): string {
    switch (level) {
      case '0':
        return '0';
      case '1':
        return '10px';
      case '2':
        return '20px';
      case '3':
        return '30px';
      case '4':
        return '40px';
      case '5':
        return '50px';
      default:
        return '0';
    }
  }

  redirectSapConfig(): void {
    this.sapConfigEvent.emit();
  }

  reset(): void {
    this.filterForm.reset();
    this.searchFilterEvent.emit({
      articleNumber: '',
      description: '',
      categories: [],
    });
    this.enableSapConfigButton = false;
  }

  search(): void {
    this.filterFieldsValues = this.filterForm.value;
    this.searchFilterEvent.emit(this.filterFieldsValues);
  }
}
